import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';


@Injectable({
  providedIn: 'root',
})

export class LoaderServices {

  subject = new Subject<boolean>();

  constructor() {
    this.subject.next(false);
  }

  setLoadingTrue() {
    this.subject.next(true)
  }

  setLoadingFalse() {
    this.subject.next(false);
  }

  getSubject() {
    return this.subject;
  }
}
